<template>
  <div class="fapiaoAdmin">
    <el-dialog
      title="申请开票"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="抬头类型">
          <el-input v-model="ruleForm.user_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="ruleForm.number" disabled></el-input>
        </el-form-item>
        <el-form-item label="开票金额" prop="amount">
          <el-input
            v-model.trim="ruleForm.amount"
            placeholder="请输入开票金额"
          ></el-input>
        </el-form-item>

        <el-form-item label="缴费时间" prop="payment_time">
          <el-date-picker
            v-model="ruleForm.payment_time"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择缴费时间"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <div class="someBox">
          <el-form-item label="发票内容" prop="invoice_content">
            <el-input v-model="ruleForm.invoice_content" disabled></el-input>
          </el-form-item>
          <el-form-item label="发票类型" prop="invoice_type">
            <el-radio-group v-model="ruleForm.invoice_type">
              <el-radio label="1">电子普通发票</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item label="其他说明" prop="other_notice">
          <el-input
            v-model.trim="ruleForm.other_notice"
            type="textarea"
            placeholder="其他说明"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            :loading="btnLoading"
            >立即创建</el-button
          >
          <el-button @click="handleClose">关 闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import * as http from "@/api/order";
export default {
  data() {
    return {
      dialogVisible: false,
      infos: JSON.parse(localStorage.getItem("userInfo")),
      btnLoading: false,
      ruleForm: {
        user_name: "",
        number: "",
        amount: "",
        payment_time: "",
        other_notice: "",
        invoice_type: "1",
        invoice_content: "现代服务*服务费",
      },
      rules: {
        amount: [
          { required: true, message: "请输入发票金额", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback();
              }
              if (!/^(\d+|\d+.\d{1,2})$/.test(value)) {
                callback(new Error("请输入正确的金额格式"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        payment_time: [
          { required: true, message: "请选择缴费时间", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          this.ruleForm.user_id = this.infos.id;
          http
            .add_invoice({ ...this.ruleForm })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.btnLoading = false;
                this.$parent.getList();
                this.handleClose();
              }
            })
            .catch((_) => {
              this.btnLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    show() {
      http.view_invoice_info({ user_id: this.infos.id }).then((res) => {
        if (res.data == null) {
          this.$message({
            message: "请先添加开票信息",
            type: "warning",
          });
          return;
        } else {
          this.dialogVisible = true;
          this.ruleForm.user_name = res.data.user_name;
          this.ruleForm.number = res.data.number;
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.resetForm("ruleForm");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
.someBox {
  ::v-deep .el-form-item {
    margin-bottom: 0px;
  }
}
</style>
